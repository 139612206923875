<template>
  <div ref="navbarWrapper" class="navbar-wrapper">
    <template v-if="isSmallScreen">
      <transition name="el-fade-in-linear">
        <div
          v-show="show_menu"
          class="navbar-container"
          :class="{ fixed: isFixed }"
        >
          <div class="navbar-inner">
            <el-menu
              :default-active="activeIndex"
              class="navbar"
              mode="horizontal"
              active-text-color="#6ecdc8"
              text-color="#000"
              :router="true"
              @select="handleSelect"
            >
              <el-menu-item
                v-if="home.index"
                :index="home.index"
                @click="handleMenuItemClick(home)"
                >{{ home.title }}</el-menu-item
              >
              <el-submenu v-if="about.index" :index="about.index">
                <template slot="title">{{ about.title }}</template>
                <el-menu-item
                  v-for="subItem in about.submenu"
                  :key="subItem.index"
                  :index="subItem.menu_index"
                  @click="handleMenuItemClick(subItem)"
                >
                  {{ subItem.menu_title }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                v-if="program.index"
                :index="program.index"
                @click="handleMenuItemClick(program)"
                >{{ program.title }}</el-menu-item
              >
              <el-menu-item
                v-if="speakers.index"
                :index="speakers.index"
                @click="handleMenuItemClick(speakers)"
                >{{ speakers.title }}</el-menu-item
              >
              <el-menu-item
                v-if="news.index"
                :index="news.index"
                @click="handleMenuItemClick(news)"
                >{{ news.title }}</el-menu-item
              >
              <el-submenu v-if="more.index" :index="more.index">
                <template slot="title">{{ more.title }}</template>
                <el-menu-item
                  v-for="subItem in more.submenu"
                  :key="subItem.index"
                  :index="subItem.menu_index"
                  @click="handleMenuItemClick(subItem)"
                >
                  {{ subItem.menu_title }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
            <div class="right-search" style="font-size: 1.5em">
              <i class="el-icon-search search" @click="toSerach"></i>
            </div>
          </div>
        </div>
      </transition>
      <transition name="el-fade-in">
        <div
          v-show="show_serch"
          class="transition-box"
          :class="{ fixed: isFixed }"
        >
          <el-input
            v-model="input"
            placeholder="Search..."
            ref="searchInput"
            @keyup.enter.native="performSearch"
          ></el-input>
          <div class="input-search">
            <i class="el-icon-close search" @click="toMenu"></i>
          </div>
        </div>
      </transition>
    </template>

    <template v-else>
      <transition name="el-fade-in-linear">
        <div
          v-show="show_menu"
          class="navbar-container"
          :class="{ fixed: isFixed }"
        >
          <div class="navbar-inner">
            <el-menu
              :default-active="activeIndex"
              class="navbar"
              mode="horizontal"
              active-text-color="#6ecdc8"
              text-color="#000"
              :router="true"
              @select="handleSelect"
            >
              <el-menu-item
                v-if="home.index"
                :index="home.index"
                @click="handleMenuItemClick(home)"
                >{{ home.title }}</el-menu-item
              >
              <el-submenu v-if="about.index" :index="about.index">
                <template slot="title">{{ about.title }}</template>
                <el-menu-item
                  v-for="subItem in about.submenu"
                  :key="subItem.index"
                  :index="subItem.menu_index"
                  @click="handleMenuItemClick(subItem)"
                >
                  {{ subItem.menu_title }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                v-if="program.index"
                :index="program.index"
                @click="handleMenuItemClick(program)"
                >{{ program.title }}</el-menu-item
              >
              <el-menu-item
                v-if="speakers.index"
                :index="speakers.index"
                @click="handleMenuItemClick(speakers)"
                >{{ speakers.title }}</el-menu-item
              >
              <el-menu-item
                v-if="news.index"
                :index="news.index"
                @click="handleMenuItemClick(news)"
                >{{ news.title }}</el-menu-item
              >
              <el-submenu v-if="more.index" :index="more.index">
                <template slot="title">{{ more.title }}</template>
                <el-menu-item
                  v-for="subItem in more.submenu"
                  :key="subItem.index"
                  :index="subItem.menu_index"
                  @click="handleMenuItemClick(subItem)"
                >
                  {{ subItem.menu_title }}
                </el-menu-item>
              </el-submenu>
              <!--            <el-menu-item v-for="item in menuItemsWithoutSubmenu" :key="item.index" :index="item.index"-->
              <!--              @click="handleMenuItemClick(item)">-->
              <!--              {{ item.title }}-->
              <!--            </el-menu-item>-->
              <!--            <el-submenu v-for="item in menuItemsWithSubmenu" :key="item.index" :index="item.index">-->
              <!--              <template slot="title">{{ item.title }}</template>-->
              <!--              <el-menu-item v-for="subItem in item.submenu" :key="subItem.index" :index="subItem.menu_index"-->
              <!--                @click="handleMenuItemClick(subItem)">-->
              <!--                {{ subItem.menu_title }}-->
              <!--              </el-menu-item>-->
              <!--            </el-submenu>-->
            </el-menu>
            <div style="font-size: 1.5em">
              <i class="el-icon-search search" @click="toSerach"></i>
            </div>
          </div>
        </div>
      </transition>
      <transition name="el-fade-in">
        <div
          v-show="show_serch"
          class="transition-box"
          :class="{ fixed: isFixed }"
        >
          <el-input
            v-model="input"
            placeholder="Search..."
            ref="searchInput"
            @keyup.enter.native="performSearch"
          ></el-input>
          <div style="font-size: 2em">
            <i class="el-icon-close search" @click="toMenu"></i>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { getParentMenu, apiFind, getapiNewsNum } from "@/api/home";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeIndex: "",
      show_menu: true,
      show_serch: false,
      input: "",
      isFixed: false,
      initialOffsetTop: 0,
      menuItemsWithoutSubmenu: [],
      menuItemsWithSubmenu: [],
      // home对象
      home: {},
      // program对象
      program: {},
      // speakers对象
      speakers: {},
      // news对象
      news: {},
      // about 对象
      about: {},
      //more 对象
      more: {},
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapGetters(["isSmallScreen", "isMediumScreen", "isBigScreen"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.initialOffsetTop =
        this.$refs.navbarWrapper.getBoundingClientRect().top;
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  updated() {
    if (this.show_search) {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getParentMenu().then((response) => {
        if (response.code === 200) {
          // 无二级菜单
          this.menuItemsWithoutSubmenu = response.data.menuItemsWithoutSubmenu;
          this.home = this.menuItemsWithoutSubmenu.find(
            (item) => item.title === "Home"
          );
          this.program = this.menuItemsWithoutSubmenu.find(
            (item) => item.title === "Program"
          );
          this.news = this.menuItemsWithoutSubmenu.find(
            (item) => item.title === "News"
          );
          this.speakers = this.menuItemsWithoutSubmenu.find(
            (item) => item.title === "Speakers"
          );

          // 有二级菜单
          this.menuItemsWithSubmenu = response.data.menuItemsWithSubmenu;
          this.about = this.menuItemsWithSubmenu.find(
            (item) => item.title === "About"
          );
          this.more = this.menuItemsWithSubmenu.find(
            (item) => item.title === "More..."
          );
          this.activeIndex = this.home.index;
          console.log("导航栏初始化", this.activeIndex);
        }
      });
    },
    getInitialOffsetTop() {
      this.initialOffsetTop =
        this.$refs.navbarWrapper.getBoundingClientRect().top +
        window.pageYOffset;
    },
    handleScroll() {
      // console.log('导航栏固定');
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      this.isFixed = scrollTop >= this.initialOffsetTop;
    },
    toSerach() {
      if (this.show_serch) {
        return;
      }

      this.show_menu = false;
      setTimeout(() => {
        this.show_serch = true;
      }, 600);
    },
    toMenu() {
      this.show_serch = false;
      setTimeout(() => {
        this.show_menu = true;
      }, 600);
    },
    performSearch() {
      const content = this.input;
      apiFind(content).then((response) => {
        if (response.code === 200) {
          const currentPath = this.$route.path;
          const homePath = response.data[0].menu_index;
          if (currentPath !== homePath) {
            this.$router.push(homePath);
          } else {
            location.reload();
          }
        }
      });
    },
    handleMenuItemClick(item) {
      console.log("点击了", item.index);
      this.activeIndex = item.index;
      console.log("当前activeIndex", this.activeIndex);
      if (item.menu_external === "1") {
        getapiNewsNum().then((response) => {
          if (response.code === 200) {
            window.open(item.url, "_blank");
            this.$router.push("/");
          }
        });
      } else {
        this.$router.push(item.index);
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  // 使用 watcher 监听路由的变化
  watch: {
    $route(to) {
      this.activeIndex = to.path;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1025px) {
  .navbar-wrapper {
    width: 100%;
    height: 60px;
  }

  .navbar-container {
    position: relative;
    width: 100%;
    background-color: white;
    z-index: 1000;
  }

  .navbar-container.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .navbar-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
  }

  .navbar {
    display: flex;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid #e7e7e7;
    font-size: 30px;
    width: 930px;
  }

  ::v-deep .el-menu {
    border-bottom: none !important;
  }

  ::v-deep .el-menu-item {
    font-size: 20px !important;
    border-bottom: none !important;
  }

  ::v-deep .menu-item.is-active {
    color: #6ecdc8 !important;
  }

  ::v-deep .el-menu-item:hover {
    color: #6ecdc8 !important;
  }

  ::v-deep .el-submenu__title {
    font-size: 20px !important;
  }

  ::v-deep .el-submenu__title:hover {
    color: #6ecdc8 !important;
  }

  .transition-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 0 20% 0 20%;
  }

  .transition-box.fixed {
    position: fixed;
    top: 0;
    /* 保证搜索框在导航栏下面 */
    left: 0;
    z-index: 1000;
  }

  .transition-box .el-input__inner {
    height: 50px !important;
    width: 500px !important;
    border: 0 !important;
    font-size: 2em !important;
  }

  .search {
    margin-left: 80%;
    cursor: pointer;
  }

  .search:hover {
    color: #6ecdc8;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-wrapper {
    width: 100%;
  }

  .navbar-container {
    position: relative;
    width: 100%;
    background-color: white;
    z-index: 1000;
    overflow: auto;
  }

  .navbar-container.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .navbar-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    .el-menu--horizontal > .el-menu-item {
      float: left;
      height: 3em;
      line-height: 3em;
      padding: 0 0.6em;
      margin: 0;
      border-bottom: 2px solid transparent;
      color: #909399;
    }
    ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
      height: 3em;
      line-height: 3em;
      padding: 0 0.6em;
      border-bottom: 2px solid transparent;
      color: #909399;
      font-size: 1em;
    }
    .el-menu {
      display: flex;
      justify-content: start;
      align-items: center;
      overflow: auto;
    }

    .right-search {
      width: 4em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  .navbar {
    /* display: flex;
    justify-content: center; */
    background-color: white;
    border-bottom: 1px solid #e7e7e7;
    /* height: 2em;
    font-size: 30px; */
    /* width: 930px; */
  }

  .el-menu {
    border-bottom: none !important;
  }

  .el-menu-item {
    font-size: 1em !important;
    border-bottom: none !important;
  }

  .menu-item.is-active {
    color: #6ecdc8 !important;
  }

  .el-menu-item:hover {
    color: #6ecdc8 !important;
  }

  .el-submenu__title {
    font-size: 1em !important;
  }

  .el-submenu__title:hover {
    color: #6ecdc8 !important;
  }

  .transition-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 0 2em;
  }

  .transition-box.fixed {
    position: fixed;
    top: 0;
    /* 保证搜索框在导航栏下面 */
    left: 0;
    z-index: 1000;
  }

  .transition-box .el-input__inner {
    /* height: 50px !important;
    width: 500px !important; */
    border: 0 !important;
    font-size: 1.6em !important;
  }

  .search {
    cursor: pointer;
  }

  .search:hover {
    color: #6ecdc8;
  }

  .input-search {
    font-size: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .navbar-wrapper {
    width: 100%;
  }

  .navbar-container {
    position: relative;
    width: 100%;
    background-color: white;
    z-index: 1000;
    overflow: auto;
  }

  .navbar-container.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .navbar-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    .el-menu--horizontal > .el-menu-item {
      float: left;
      height: 3em;
      line-height: 3em;
      padding: 0 0.6em;
      margin: 0;
      border-bottom: 2px solid transparent;
      color: #909399;
    }
    ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
      height: 3em;
      line-height: 3em;
      padding: 0 0.6em;
      border-bottom: 2px solid transparent;
      color: #909399;
      font-size: 1em;
    }
    .el-menu {
      display: flex;
      justify-content: start;
      align-items: center;
      overflow: auto;
    }

    .right-search {
      width: 4em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  .navbar {
    /* display: flex;
    justify-content: center; */
    background-color: white;
    border-bottom: 1px solid #e7e7e7;
    /* height: 2em;
    font-size: 30px; */
    /* width: 930px; */
  }

  .el-menu {
    border-bottom: none !important;
  }

  .el-menu-item {
    font-size: 1em !important;
    border-bottom: none !important;
  }

  .menu-item.is-active {
    color: #6ecdc8 !important;
  }

  .el-menu-item:hover {
    color: #6ecdc8 !important;
  }

  .el-submenu__title {
    font-size: 1em !important;
  }

  .el-submenu__title:hover {
    color: #6ecdc8 !important;
  }

  .transition-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 0 2em;
  }

  .transition-box.fixed {
    position: fixed;
    top: 0;
    /* 保证搜索框在导航栏下面 */
    left: 0;
    z-index: 1000;
  }

  .transition-box .el-input__inner {
    /* height: 50px !important;
    width: 500px !important; */
    border: 0 !important;
    font-size: 1.6em !important;
  }

  .search {
    cursor: pointer;
  }

  .search:hover {
    color: #6ecdc8;
  }

  .input-search {
    font-size: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
