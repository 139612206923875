const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  isSmallScreen: state => state.screen.screenWidth < 768,
  isMediumScreen: state => state.screen.screenWidth >= 768 && state.screen.screenWidth <= 1024,
  isBigScreen: state => state.screen.screenWidth > 1024,
  isMobile: state => state.app.isMobile
}
export default getters
