<template>
  <div id="app">
    <el-backtop v-if="!shouldExcludeGlobalComponents">
      <div class="backtop">UP</div>
    </el-backtop>
    <MainHeader v-if="!shouldExcludeGlobalComponents" />
    <TopNav v-if="!shouldExcludeGlobalComponents" />
    <router-view />
    <MainBottom v-if="!shouldExcludeGlobalComponents" />
  </div>
</template>

<script>
import MainHeader from "@/components/homeIndex/MainHeader.vue";
import TopNav from "@/components/homeIndex/TopNav.vue";
import MainBottom from "@/components/homeIndex/MainBottom.vue";

export default {
  name: "App",
  components: { MainHeader, TopNav, MainBottom },

  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    shouldExcludeGlobalComponents() {
      return this.$route.meta.excludeGlobalComponent;
    },
  },
};
</script>
<style lang="scss">
#app {
  overflow: hidden;
}

@media (max-width: 767px) {
  /* 确保样式应用到 body 下的下拉菜单 */
  body .el-select-dropdown {
    width: 80vw !important;
    // width: 100vw !important;
    // overflow: auto !important;
  }

  .el-dialog__body .bule {
    width: 100%;
    height: 6px;
    background-color: #05b8b0;
  }

  .el-date-picker {
    .el-date-picker__header {
      margin: 4px !important;
      .el-picker-panel__icon-btn {
        padding: 0 !important;
      }
    }
  }

  .el-select-dropdown__item {
    height: auto;
    span {
      display: block;
      white-space: normal;
      font-size: 12px;
    }
  }
}
</style>
