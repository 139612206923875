import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import './assets/styles.css'; // 引入公共样式
import 'element-ui/lib/theme-chalk/base.css';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import download from '@/plugins/download';
import _ from 'lodash';


import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
// 引入 swiper.css
import 'swiper/css/swiper.min.css' // 注意这里的引入



Vue.config.productionTip = false
Vue.prototype.$download = download;

Vue.use(ElementUI, { locale } ,VideoPlayer);

const updateScreenWidth = () => {
  store.dispatch('updateScreenWidth');
};
const debouncedUpdateScreenSize = _.debounce(updateScreenWidth, 200);
window.addEventListener('resize', debouncedUpdateScreenSize);
updateScreenWidth(); // 初始化设置

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
