const screen = {
  state: {
    screenWidth: window.innerWidth
  },
  mutations: {
    SET_SCREEN_WIDTH(state, width) {
      state.screenWidth = width;
    }
  },
  actions: {
    updateScreenWidth({ commit }) {
      commit('SET_SCREEN_WIDTH', window.innerWidth);
    }
  }
}

export default screen
