<!-- MainBottom -->
<template>
  <div class="container-mainbottom">
    <template v-if="isSmallScreen || isMediumScreen">
      <div class="content">
        <!-- <div class="bottom_enquiry">
          <div>
            
          </div>
        </div> -->

        <div class="bottom_logo">
          <div>
            <div class="top">
              <p>{{ bottom.left_title1 }}</p>
              <img :src="bottom.left_logo1" alt="" />
            </div>
            <div class="bottom">
              <p>{{ bottom.left_title2 }}</p>
              <img :src="bottom.left_logo2" alt="" />
            </div>
          </div>
        </div>
        <div class="top">
          <p>{{ bottom.url_title1 }}</p>
          <span @click="goToURL(bottom.ur_content_url1)">{{
            bottom.ur_content1
          }}</span>
        </div>
        <div class="bottom">
          <p>{{ bottom.url_title2 }}</p>
          <span @click="goToURL(bottom.ur_content_url2)">{{
            bottom.ur_content2
          }}</span>
        </div>
        <div class="bottom_Waitro">
          <div>
            <p>{{ bottom.url_title3 }}</p>
            <div>
              <span @click="goToURL(bottom.ur_content_url31)">{{
                bottom.ur_content31
              }}</span>
            </div>
            <div>
              <span @click="goToURL(bottom.ur_content_url32)">{{
                bottom.ur_content32
              }}</span>
            </div>
            <span @click="goToURL(bottom.ur_content_url33)">{{
              bottom.ur_content33
            }}</span>
          </div>
        </div>
        <div class="bottom_Legal">
          <div>
            <p>Agreement</p>
            <div @click="selectFileType(userOssId)">
              <span>User Agreement</span>
            </div>
            <div @click="selectFileType(registerOssId)">
              <span>Registration Agreement</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="content">
        <div class="bottom_logo">
          <div>
            <div class="top">
              <p>{{ bottom.left_title1 }}</p>
              <img :src="bottom.left_logo1" alt="" />
            </div>
            <div class="bottom">
              <p>{{ bottom.left_title2 }}</p>
              <img :src="bottom.left_logo2" alt="" />
            </div>
          </div>
        </div>
        <div class="bottom_enquiry">
          <div>
            <div class="top">
              <p>{{ bottom.url_title1 }}</p>
              <span @click="goToURL(bottom.ur_content_url1)">{{
                bottom.ur_content1
              }}</span>
            </div>
            <div class="bottom">
              <h4>{{ bottom.url_title2 }}</h4>
              <span @click="goToURL(bottom.ur_content_url2)">{{
                bottom.ur_content2
              }}</span>
            </div>
          </div>
        </div>
        <div class="bottom_Waitro">
          <div style="width: 80%; max-width: 100%">
            <p>{{ bottom.url_title3 }}</p>
            <div>
              <span @click="goToURL(bottom.ur_content_url31)">{{
                bottom.ur_content31
              }}</span>
            </div>
            <div>
              <span @click="goToURL(bottom.ur_content_url32)">{{
                bottom.ur_content32
              }}</span>
            </div>
            <span @click="goToURL(bottom.ur_content_url33)">{{
              bottom.ur_content33
            }}</span>
          </div>
        </div>
        <div class="bottom_Legal">
          <div style="width: 80%">
            <p>Agreement</p>
            <div @click="selectFileType(userOssId)">
              <span>User Agreement</span>
            </div>
            <div @click="selectFileType(registerOssId)">
              <span>Registration Agreement</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- <div class="lfti">
      <div class="imgs">
        <img src="../../assets/bottomIcons/linkedin.png" :width="50" alt="">
        <img src="../../assets/bottomIcons/facebook.png" alt="">
        <img src="../../assets/bottomIcons/twitter.png" alt="">
        <img src="../../assets/bottomIcons/instagram.png" alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
import { getApiFooter } from "@/api/home";
import { getApiProtocol } from "@/api/order";
import { mapGetters } from "vuex";

export default {
  name: "MainBottom",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      bottom: {},
      files: [],
      userOssId: 0,
      registerOssId: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapGetters(["isSmallScreen", "isMediumScreen", "isBigScreen"]),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getList() {
      getApiFooter().then((response) => {
        this.bottom = response.data;
      });
      getApiProtocol().then((response) => {
        this.userOssId = response.data.type1.protocol_url_id;
        this.registerOssId = response.data.type2.protocol_url_id;
      });
    },
    goToURL(url) {
      window.open(url, "_blank");
    },
    selectFileType(ossId) {
      console.log("this.ossId:", ossId);
      if (this.$download && this.$download.oss) {
        this.$download.oss(ossId);
      } else {
        console.error("Download service is not available.");
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类

@media (min-width: 1025px) {
  .container-mainbottom {
    background-color: #474c55;
    padding: 0;
  }

  .content {
    background-color: #474c55;
    height: 230px;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bottom_logo {
    width: 25%;
    line-height: 30px;
    color: white;
    display: flex;
    justify-content: center;
  }
  .bottom_logo img {
    width: 280px;
    height: 45px;
  }
  .bottom_enquiry {
    width: 25%;
    line-height: 40px;
    color: white;
    display: flex;
    justify-content: center;
  }

  .bottom_Waitro {
    width: 25%;
    line-height: 40px;
    color: white;
    display: flex;
    justify-content: center;
  }

  .bottom_Legal {
    width: 25%;
    line-height: 60px;
    color: white;
    display: flex;
    justify-content: center;
  }

  span {
    color: #05b8b0;
    text-decoration: underline;
    cursor: pointer;
    /* 光标变成小手 */
  }

  .lfti {
    background-color: #05b8b0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgs {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container-mainbottom {
    background-color: #474c55;
    padding: 0;
  }

  .content {
    background-color: #474c55;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 三列布局 */
    gap: 4em; /* 项之间的间距 */
    padding: 0 2em 2em 2em;
    > .bottom_logo {
      color: white;
      padding-top: 2em;
      grid-column: span 2;
      p {
        font-size: 1.2em;
      }
      img {
        width: 16em;
      }
    }
    > .top p,
    > .bottom p,
    > .bottom_Waitro p,
    > .bottom_Legal p {
      font-size: 1.2em;
      color: white;
    }

    > .top,
    > .bottom,
    > .bottom_Waitro,
    > .bottom_Legal {
      font-size: 1.2em;
    }

    > .bottom_Waitro div,
    > .bottom_Legal div {
      line-height: 3em;
    }
  }

  span {
    color: #05b8b0;
    text-decoration: underline;
    cursor: pointer;
    /* 光标变成小手 */
  }

  .lfti {
    background-color: #05b8b0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgs {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .container-mainbottom {
    background-color: #474c55;
    padding: 0;
  }

  .content {
    background-color: #474c55;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 三列布局 */
    gap: 2em; /* 项之间的间距 */
    padding: 0 2em 2em 2em;
    > .bottom_logo {
      color: white;
      padding-top: 2em;
      grid-column: span 2;
      p {
        font-size: 0.8em;
      }
      img {
        width: 12em;
      }
    }
    > .top p,
    > .bottom p,
    > .bottom_Waitro p,
    > .bottom_Legal p {
      font-size: 0.8em;
      color: white;
    }

    > .top,
    > .bottom,
    > .bottom_Waitro,
    > .bottom_Legal {
      font-size: 0.6em;
    }

    > .bottom_Waitro div,
    > .bottom_Legal div {
      line-height: 3em;
    }
  }

  span {
    color: #05b8b0;
    text-decoration: underline;
    cursor: pointer;
    /* 光标变成小手 */
  }

  .lfti {
    background-color: #05b8b0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgs {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
