<!-- 头部 -->
<template>
  <div class="container">
    <div class="grid-container">
      <!-- <template v-if="isSmallScreen">
        <div class="logo_container">
          <div class="logo" @click="goToHomePage">
            <img :src="header.src" alt="" />
          </div>
          <div class="logo_text">
            <img :src="header.src2" alt="" />
          </div>
        </div>
      </template> -->

      <template v-if="isBigScreen">
        <div class="logo" @click="goToHomePage">
          <img :src="header.src" alt="" />
        </div>
        <div class="logo_text">
          <img :src="header.src2" alt="" />
        </div>
        <div class="space"></div>
        <div class="register">
          <div class="register_btn" @click="toRegisterAndLogin">
            {{ isLoggedIn ? "My Account" : "REGISTER NOW" }}
          </div>
          <div class="content_text">
            <div class="date_text">{{ header.content1 }}</div>
            <div class="location_text">{{ header.content2 }}</div>
          </div>
        </div>
      </template>

      <template v-if="isMediumScreen">
        <div class="logo" @click="goToHomePage">
          <img :src="header.src" alt="" />
        </div>
        <div class="logo_text">
          <img :src="header.src2" alt="" />
        </div>
        <div class="register">
          <div class="register_btn" @click="toRegisterAndLogin">
            {{ isLoggedIn ? "My Account" : "REGISTER NOW" }}
          </div>
          <div class="content_text">
            <div class="date_text">{{ header.content1 }}</div>
            <div class="location_text">{{ header.content2 }}</div>
          </div>
        </div>
      </template>

      <template v-if="isSmallScreen">
        <div class="logo" @click="goToHomePage">
          <img :src="header.src" alt="" />
        </div>
        <div class="logo_text">
          <img :src="header.src2" alt="" />
        </div>
        <div class="register">
          <div class="register_btn" @click="toRegisterAndLogin">
            {{ isLoggedIn ? "My Account" : "REGISTER NOW" }}
          </div>
          <div class="content_text">
            <div class="date_text">{{ header.content1 }}</div>
            <div class="location_text">{{ header.content2 }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getApiHead } from "@/api/home";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";
export default {
  name: "MainHeader",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      header: {},
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapGetters(["isSmallScreen", "isMediumScreen", "isBigScreen"]),
    isLoggedIn() {
      return getToken(); // 假设登录状态存储在全局的user模块中
    },
  },
  //监控data中的数据变化
  watch: {},
  created() {
    this.getList();
  },
  beforeDestroy() {},
  //方法集合
  methods: {
    getList() {
      getApiHead().then((response) => {
        this.header = response.data;
      });
    },
    goToHomePage() {
      // 获取当前路由路径
      const currentPath = this.$route.path;
      // 设置主页面的路由路径
      const homePath = "/home-view";

      // 检查当前路径是否与主页面路径相同
      if (currentPath !== homePath) {
        // 如果不同，则执行导航操作
        this.$router.push(homePath);
      }
    },
    toRegisterAndLogin() {
      const currentPath = this.$route.path;
      const homePath = this.isLoggedIn
        ? "/personal-center"
        : "/Register-and-login";
      if (currentPath !== homePath) {
        this.$router.push(homePath);
      }
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类

@media (min-width: 1025px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 四栏布局 */
    gap: 0px;
    width: 100%;
    height: 100%;
    max-width: 1600px;
    background-color: #e4eced;
  }

  .container {
    width: 100%;
    height: 200px;
    // padding: 0.5% 0 0 0;
    background-color: #e4eced;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    width: 60%;
    height: 40%;
    color: #000000;
    font-size: clamp(14px, 1vw, 24px);
    font-weight: 600;
    color: #3f3f3f;
  }

  .date_text {
    font-weight: 600;
    margin-bottom: 10px;
    /* 间距调整 */
  }

  .location_text {
    font-weight: normal;
    /* 设置为正常字体 */
  }

  .register {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
  }

  .register_btn {
    width: 55%;
    min-width: 215px;
    height: 35%;
    background-color: #05b8b0;
    color: #ffffff;
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 5%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* 动画效果 */
    animation: slideInFromRight 1s ease-out;
    text-align: center;
    line-height: 65px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .logo,
  .logo_text {
    padding: 20px 0;
  }

  .logo img {
    width: 80%;
    height: auto;
  }

  .logo_text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .logo_text img {
    width: 80%;
    height: auto;
  }

  .space {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 四栏布局 */
    gap: 40px;
    width: 100%;
    height: 100%;
    background-color: #e4eced;
  }

  .container {
    width: 100%;
    height: 200px;
    // padding: 0.5% 0 0 0;
    background-color: #e4eced;
  }

  .content_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    color: #000000;
    font-size: clamp(14px, 1vw, 24px);
    font-weight: 600;
    color: #3f3f3f;
  }

  .date_text {
    font-weight: 600;
    margin-bottom: 10px;
    /* 间距调整 */
  }

  .location_text {
    font-weight: normal;
    /* 设置为正常字体 */
  }

  .register {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
  }

  .register_btn {
    padding: 0 20px;
    background-color: #05b8b0;
    color: #ffffff;
    font-size: clamp(14px, 2vw, 20px);
    font-weight: 600;
    margin-bottom: 5%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* 动画效果 */
    animation: slideInFromRight 1s ease-out;
    text-align: center;
    line-height: 3em;
  }

  .logo_container {
    width: 100%;
    padding: 20px 0;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
  }

  .logo img,
  .logo_text img {
    width: 100%;
  }

  .logo_text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .logo,
  .logo_text {
    padding: 20px 0px;
  }
}

@media (max-width: 767px) {
  .grid-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
    width: 100%;
    height: 100%;
    background-color: #e4eced;
  }

  .container {
    width: 100%;
    height: 200px;
    // padding: 0.5% 0 0 0;
    background-color: #e4eced;
  }

  .content_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    color: #000000;
    font-size: clamp(14px, 1vw, 24px);
    font-weight: 600;
    color: #3f3f3f;
    margin-left: 3em;
  }

  .date_text {
    font-weight: 600;
    margin-bottom: 10px;
    /* 间距调整 */
  }

  .location_text {
    font-weight: normal;
    /* 设置为正常字体 */
  }

  .register {
    width: 100%;
    height: 100%;
    display: flex;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 1em;
  }

  .register_btn {
    padding: 0 1em;
    background-color: #05b8b0;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* 动画效果 */
    animation: slideInFromRight 1s ease-out;
    text-align: center;
    line-height: 3em;
  }

  .logo_container {
    width: 100%;
    padding: 20px 0;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .logo img,
  .logo_text img {
    width: 70%;
  }

  .logo_text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .logo,
  .logo_text {
    padding: 8px 0px;
  }
}
</style>
